import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private isConnected$ = signal(true);

  constructor() {
    window.addEventListener('offline', () => this.isConnected$.set(false));

    window.addEventListener('online', () => this.isConnected$.set(true));
  }

  get isConnected() {
    return this.isConnected$();
  }
}
