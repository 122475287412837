<main class="d-flex flex-column h-100" [dir]="languageService.getLanguage === 'ar' ? 'rtl' : 'ltr'">

  <!-- <msn-header *ngIf="showHeaderFooter"></msn-header> -->
  <msn-header></msn-header>
  <div class="content d-flex flex-column h-100">
    <router-outlet></router-outlet>
    <!-- <msn-footer *ngIf="showHeaderFooter"></msn-footer> -->
    <msn-footer></msn-footer>
  </div>
</main>
