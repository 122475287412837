<div class="container pt-5 pb-5 ">
    <div class="claim-file pb-5 mb-4">
        <div class="text-center">
            <h2 class="claim-title mt-3 mb-3">{{"global.fillClaim"| translate}}</h2>
            <p> {{"claim.desc1"| translate}}</p>
            <p> {{"claim.desc2"| translate}}</p>
        </div>
    </div>
    <div class="claim-file row justify-content-between">
        <div class="col-md-6">
            <img src="../../../assets/images/fileAclaim-img.svg" class="w-100">
        </div>
        <div class="col-md-6">
            <h2 class="claim-title mt-3 mb-3">{{"claim.homeVisit"| translate}}</h2>
            <p> {{"claim.desc3"| translate}}</p>
            <p> {{"claim.desc4"| translate}}</p>
            <div class="mt-5">
                <span class="home-label">{{"claim.homeEligability"|translate}}:</span>
                <div class="eligability-items mt-4 row">
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/cookers.svg">
                        <h6>{{"claim.Cookers"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/dishwashers.svg">
                        <h6>{{"claim.Dishwashers"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/dryers.svg">
                        <h6>{{"claim.Dryers"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/freezer.svg">
                        <h6>{{"claim.Freezer"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/refrigerator.svg">
                        <h6>{{"claim.Refrigerator"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-5 eligability-item">
                        <img src="../../../assets/images/fileAclaim/tvs.svg">
                        <h6>{{"claim.TVs"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-4 eligability-item">
                        <img src="../../../assets/images/fileAclaim/washingM.svg">
                        <h6>{{"claim.WashingMachine"|translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="claim-file row justify-content-between pt-5 mt-4 pb-5">
        <div class="col-md-6">
            <h2 class="claim-title mt-3 mb-3">{{"claim.StoreVisit"| translate}}</h2>
            <p> {{"claim.desc5"| translate}}</p>
            <div class="mt-5 mb-3">
                <span class="home-label">{{"claim.deviceEligability"|translate}}:</span>
                <div class="eligability-items mt-4 row">
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/Cameras.svg">
                        <h6>{{"claim.Cameras"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/Desktop.svg">
                        <h6>{{"claim.Desktop"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/Laptop.svg">
                        <h6>{{"claim.Laptop"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/Playstation.svg">
                        <h6>{{"claim.Playstation"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-3 eligability-item">
                        <img src="../../../assets/images/fileAclaim/Projectors.svg">
                        <h6>{{"claim.Projectors"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-5 eligability-item">
                        <img src="../../../assets/images/fileAclaim/tvs.svg">
                        <h6>{{"claim.tvSmallerThan"|translate}}</h6>
                    </div>
                    <div class="col-6 col-lg-4 eligability-item">
                        <img src="../../../assets/images/fileAclaim/SmallApplainces.svg">
                        <h6>{{"claim.SmallApplainces"|translate}}</h6>
                    </div>
                </div>
            </div>
            <div class="go-store">
                <p>{{"claim.RiyadhCustomers"|translate}}, {{"claim.goDirectlyTo"|translate}} <a  href="https://www.google.com/maps/dir/24.770571,46.764931/@24.7707011,46.7598984,16z?entry=ttu" target="_blank">{{"claim.AlHamraServiceCenter" | translate}}</a></p>
            </div>
            <div class="find-store">
                <p>{{"claim.outsideRiyadh"|translate}}  {{"claim.FindNearestStore"|translate}}: <a target="_blank" href="https://www.extra.com/{{lang}}-sa/store-finder">{{"claim.StoreFinder" | translate}}</a></p>
            </div>
        </div>
        <div class="col-md-6 pt-4">
            <img src="../../../assets/images/fileAclaim-img2.svg" class="w-100">
        </div>
    </div>
</div>
