import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LanguageService } from './modules/core/services/language';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';
 
@Component({
  selector: 'msn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'Check Now';
  showHeaderFooter = true;

  constructor(private router: Router,
    public translateService: TranslateService,

    public languageService: LanguageService,
  ) {
    translateService.addLangs(['en', 'ar']);
    var currentLang = localStorage.getItem('currentLang');
    if (currentLang != null && currentLang != 'null') {
      this.languageService.setLanguage(currentLang);
      this.translateService.use(currentLang);
    } else {
      this.languageService.setLanguage('ar');
      translateService.use('ar');
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.showHeaderFooter = !(event.url === '/login' || event.url === '/signup');
    });

  }


}
