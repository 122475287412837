import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { SignupComponent } from './components/signup/signup.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLoaderComponent } from './components/app-loader/app-loader.component';
import { ControlErrorDisplayComponent } from './components/control-error-display/control-error-display.component';
import { InputMaskModule } from 'primeng/inputmask';
import { DownloadAppComponent } from './components/download-app/download-app.component';


@NgModule({
  declarations: [LoginComponent, HeaderComponent, FooterComponent, SignupComponent, AppLoaderComponent,ControlErrorDisplayComponent, DownloadAppComponent],
  imports: [CommonModule, SharedModule, BsDropdownModule, NgxIntlTelInputModule,FormsModule, ReactiveFormsModule,InputMaskModule],
  exports: [HeaderComponent, FooterComponent,AppLoaderComponent,ControlErrorDisplayComponent],
})
export class CoreModule {}
