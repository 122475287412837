<div class="contact-us">
    <div class="container">
        <div class="row pt-5 pb-5 justify-content-between">
            <div class="col-md-6">
                <div class="row mb-4">
                  <span class="home-label">{{"home.label" | translate}}</span>
                  <h2 class="get-in-touch">
                        {{"shared.getInTouch" | translate }}
                  </h2>
                  <p>{{"shared.contactDesc" | translate}}</p>
                </div>
                <form class=" mt-4">
                    <div class="row">
                        <div class="col-md-12  mb-4">
                            <label class="text-primary mb-2">{{"shared.name" | translate}}</label>
                            <div class="form-input">
                              <input type="text" class="form-control">
                                <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                            </div>                                
                        </div>
                        <div class="col-md-6 mb-4">
                          <label class="text-primary mb-2">{{"shared.email" | translate}}</label>
                            <div class="form-input">
                              <input type="email" class="form-control">
                                <img src="../../../../assets/images/icons/sms.svg" class="input-icon" alt="">
                            </div>                        
                        </div>
                        <div class="col-md-6 mb-4">
                          <label class="text-primary mb-2">{{"shared.phone" | translate}}</label>
                            <div class="form-input">
                                <input type="tel" class="form-control">
                                <img src="../../../../assets/images/icons/phone-dark.svg" class="input-icon" alt="">
                            </div>
                        </div>
                        <div class="col-md-12 mb-4">
                            <label class="text-primary mb-2">{{"shared.message" | translate}}</label>
                            <div class="form-input">
                                <textarea class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button type="submit" class="btn btn-primary">{{'shared.submit'|translate}} <i
                                class="fas fa-arrow-right"></i></button>
                      </div>
                </form>
              </div>
            <div class="col-md-5">
            <div class="mt-3">
              <img src="../../../../../assets/images/workerX.png" class="w-100" alt="">
            </div>
          </div>
        </div>
      </div>
</div>