import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { BehaviorSubject, Observable, Subscriber, of } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';
import { CustomerViewModel } from '../../models/customer-view-model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private router: Router, private route: ActivatedRoute) {}
  isLoggedIn(): boolean {
    const user = this.getCurrentUser();
    return user != null;
  }
  login(user: LoginResponse) {
    localStorage.setItem(this.currentUser, JSON.stringify(user));
    this.userChange.next(user);
  }
  getCurrentUser(): LoginResponse | null {
    const item = localStorage.getItem(this.currentUser);

    const user: LoginResponse = item != null ? JSON.parse(item) : null;
    return user;
  }
  getCurrentUserIdString(): string | null {
    let result: string;
    const user = this.getCurrentUser();
    if (user) {
      const token = jwt_decode.jwtDecode(user.token) as any;
      
      if (token) {
        return token.unique_name;
      }
    }
    return null;
  }

  logOut(showAuthError = false) {
    localStorage.removeItem(this.currentUser);
    this.userChange.next(null);

    if (showAuthError) {
      // this.notificationService.showErrorTranslated('error.shared.operationFailed','');
    }
    this.router.navigate(['login']);
  }

  getToken(): any {
    const user = this.getCurrentUser();
    if (user) return user.token;
  }
  updateCurrentUser(model:CustomerViewModel){
    const user = this.getCurrentUser();
    if(user){
      user.phone=model.phone;
      user.email=model.email;
      user.arabic_first_name=model.arabic_first_name;
      user.arabic_last_name=model.arabic_last_name;
      user.first_name=model.first_name;
      user.last_name=model.last_name;
      user.profileImageUrl=model.profileImageUrl;
      user.isEmailVerification=true;
      localStorage.setItem(this.currentUser, JSON.stringify(user));
      this.userChange.next(user);
    }
  }
  //observable on user change
  onUserChange(): Observable<LoginResponse|null> {
    return this.userChange.asObservable();
  }
  private userChange: BehaviorSubject<LoginResponse|null>=new BehaviorSubject({} as LoginResponse|null);

  private currentUser = 'currentUser';
  //User: LoginResponse;
}