export enum URL {
  Test = 'https://extraoutservice-backend.azurewebsites.net/api',

  TestBase = 'https://extraoutservice-backend.azurewebsites.net',
  Development = 'http://localhost:44225/api', 
  DevelopmentBase = 'http://localhost:44225', 

  redirectUrlTest = 'https://checkout.payfort.com/FortAPI/paymentPage',

  Download = 'https://extraoutservice-backend.azurewebsites.net/',

  Production = 'http://localhost:44225/api',
  ProductionBase = 'http://localhost:44225',

  PreProduction = 'es',
  UAT = '',
}
