import { Injectable } from '@angular/core';
import { CurrentUserService } from '../auth/currentuser.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';

// eslint-disable-next-line no-var
declare var gtag : any;

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  constructor(
    private currentUserService: CurrentUserService,
    private translateService: TranslateService
  ) {
    
   
  }

  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    gtag('config', 'G-GBQ31NENK1',{
      'user_id': this.currentUserService.getCurrentUser()?.phone
      ,'language':  this.translateService.currentLang
      });
    gtag('event', eventName, {
    // event Type - example: 'SCROLL_TO_TOP_CLICKED'
    'event_category': eventCategory,
    // the label that will show up in the dashboard as the events name
    'event_label': eventName,
    // a short description of what happened
    'value': eventDetails,
    'user_id': this.currentUserService.getCurrentUser()?.phone,
    'language': this.translateService.currentLang
    })
  }

}