import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './components/button/button.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { TitleComponent } from './components/title/title.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

const CUSTOM_COMPONENTS = [ButtonComponent, TitleComponent, InfoBoxComponent];

@NgModule({
  declarations: [...CUSTOM_COMPONENTS],
  imports: [CommonModule, TranslateModule, RouterModule],
  exports: [
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ...CUSTOM_COMPONENTS,
  ],
})
export class SharedModule {}
