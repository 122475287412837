import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderConfig } from '../../configurations';
import { ButtonTypes } from 'src/app/modules/shared/enums/button-types.enum';
import { INavItem } from 'src/app/modules/shared/interfaces';
import { LanguageService } from '../../services/language';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CurrentUserService } from '../../services/auth/currentuser.service';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from '../../services/auth/notification.service';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';
import { HubNotifictionService } from '../../services/HubNotifiction.service';
import { EventEmitterService } from '../../services/EventEmitterService';
import { AnalyticsService } from '../../services';

@Component({
  selector: 'msn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  language!: string;
  subscriptionTranslate!: Subscription;
  subscriptions: Subscription[] = [];
  title!: string;
  navItems!: INavItem[];
  isLoggedIn = false;
  currentUser: LoginResponse | null = null;

  menuOpen = false;

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  config = HeaderConfig;
  buttonTypes = ButtonTypes;
  lang!:string;
  constructor(
    public languageService: LanguageService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private _NotificationDetailsService: NotificationService,
    private _eventEmitterService: EventEmitterService,
    private analyticsService: AnalyticsService,
    private _NotificationsService: NotificationService,
    public HubNotifictionService: HubNotifictionService,

    private currentUserService: CurrentUserService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuOpen = false; // Close the menu on route change
      }
    });
    this.analyticsService.trackEvent(
      'Start Session',
      'Start Session',
      `language ${this.lang}`
    );
  }

  ngOnInit(): void {
    this.getNavItems();
    this.lang = this.translateService.currentLang;
    this.analyticsService.trackEvent(
      'Start Session',
      'Start Session',
      `language ${this.lang}`
    );
    this.subscriptionTranslate = this.translateService.onLangChange.subscribe(
      (lang) => {
        this.lang = lang.lang;
      });
    this.isLoggedIn = this.currentUserService.isLoggedIn();
    this.currentUser = this.currentUserService.getCurrentUser();
    this.HubNotifictionService.startConnection();
    this.HubNotifictionService.addNotificationListener()
    const subscription = this.currentUserService
      .onUserChange()
      .subscribe((user) => {
        this.isLoggedIn = this.currentUserService.isLoggedIn();
        this.currentUser = this.currentUserService.getCurrentUser();
        this.UnSeenNotifications = 0;
        this. pageIndex = 0;
        this.pageSize = 10;
        this.notificationMenus=null;
        this.getNavItems();
        this.getNotificationsByUserId();
        this.getCountUnSeenNotifications();
      });
    this.subscriptions.push(subscription);
    this._eventEmitterService.onReloadNotifications.subscribe(res => {
      this.getCountUnSeenNotifications();
    });
    this.getNotificationsByUserId();
    this.getCountUnSeenNotifications();

  }

  changeLang(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('currentLang', lang);

    this.languageService.setLanguage(lang);
  }

  getNavItems(): void {
    this.navItems = [
      {
        name: 'menu.home',
        route: '/home',
        hide: false,
      },
      {
        name: 'menu.requests',
        route: '/warranty/list',
        hide: !this.isLoggedIn,
      },
      {
        name: 'menu.profile',
        route: '/editProfile',
        hide: !this.isLoggedIn,
      },
      {
        name: 'global.fillClaim',
        route: '/file-a-claim',
        hide: false,
      },
      {
        name: 'menu.contactUs',
        route: '/contact',
        hide: true,
      },

      {
        name: 'menu.privacyPolicy', // Ex: to Hide nave
        route: '/privacyPolicy',
        hide: true,
      },
    ];
  }
  logout(): void {
    this.currentUserService.logOut();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }

  DismissNotificationMinue() {
    document.querySelector('#content')!.addEventListener('click', event => {
      const notificationCard: any = document.querySelector('#notificationCard');
      const withinBoundaries = event.composedPath().includes(notificationCard);
      if (withinBoundaries) {
        notificationCard.style.top = '71px';
      } else {
        notificationCard.style.top = '-150%';
        document
          .querySelector('.active-notification')
          ?.classList.remove('active-notification');
      }
    });
  }
  getCountUnSeenNotifications() {
    this._NotificationDetailsService
      .getCountUnSeenNotificationsByUser(this.currentUser!.id)
      .subscribe(
        res => {
          this.UnSeenNotifications = res;
          //    this.UnSeenNotifications = 5;
        },
        error => {
          // this.notificationService.showErrorTranslated(
          //   'error.shared.operationFailed',
          //   error
          // );
        },
        () => {}
      );
  }
  getNotificationsByUserId() {
    this._NotificationsService
      .GetAllNotificationsByUserIdAsync(
        this.pageIndex,
        this.pageSize,
        this.currentUser!.id,
        'CreationDate'
      )
      .subscribe(
        res => {
          if (this.pageIndex == 0) {
            this.notificationMenus = res;
          } else {
            this.notificationMenus.collection?.push(...res.collection!);
            this.notificationMenus.pagination = res.pagination;
          }
        },
        error => {
          
        },
        () => {}
      );
  }
  addActiveNotification(el: HTMLElement) {
    /*     const notificationCard: HTMLElement =
      document.querySelector('#notificationCard')!; */
    this.seenAllNotification();
  }
  isOpenMessage = false;
  seenNotification(notification: any) {
    this.isOpenMessage = true;
    this._NotificationDetailsService
      .readNotificationByUser(this.currentUser?.id, notification.id)
      .subscribe(
        res => {
          const x = res;
          this.getNotificationsByUserId();
          this.getCountUnSeenNotifications();

          this.goToLink(notification.url);
          this.isOpenMessage = false;
        },
        error => {
        
        },
        () => {}
      );
  }
  goToLink(url: string) {
    if (url != null) {
      if (url?.includes('http')) {
        window.open(url, '_blank');
      } else {
        this.router.navigate([url]);
      }
    }
  }
  onScrollDown(s: any) {
    if (s.target.offsetHeight + s.target.scrollTop >= s.target.scrollHeight)
      if (
        this.notificationMenus?.collection?.length <
        this.notificationMenus?.pagination?.totalCount
      ) {
        this.pageIndex = this.pageIndex + 1;
        this.getNotificationsByUserId();
      }
  }
  seenAllNotification() {
    this.getNotificationsByUserId();
    this._NotificationDetailsService
      .seenAllNotificationCurrentUserId()
      .subscribe(
        res => {
          const x = res;
          this.getCountUnSeenNotifications();
        },
        error => {
       
        },
        () => {}
      );
  }
  UnSeenNotifications = 0;
  pageIndex = 0;
  pageSize = 10;
  notificationMenus: any;
}
