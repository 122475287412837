import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { PageTitleService } from '../../services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language';
import { Router } from '@angular/router';
import { CurrentUserService } from '../../services/auth/currentuser.service';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';
import { CustomerViewModel } from '../../models/customer-view-model';
import { AnalyticsService, AuthService } from '../../services';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'msn-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  currentYear = new Date().getFullYear();
  language!: string;
  subscriptionTranslate!: Subscription;

  subscriptions: Subscription[] = [];
  signupForm!: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Qatar];
  currentuser!:LoginResponse|null;
  model!: CustomerViewModel;
  constructor(
    private fb: FormBuilder,
     private pageTitle: PageTitleService,
    public languageService: LanguageService,
    private translateService: TranslateService,
    private notificationService: NotificationService, 
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService,

    private currentuserService: CurrentUserService

  ) {
  
  }

  ngOnInit(): void {
    this.currentuser=this.currentuserService.getCurrentUser();
    if(!this.currentuser){
      this.router.navigate(['/login']);
    }
    this.analyticsService.trackEvent('page_view', 'signup', `signup_page_view for ${this.currentuser?.phone}`);
    this.setPageTitle();
    //this.setLanguageSubscriber();
    this.buildForm();

  }
  buildForm(): void {
    this.signupForm = this.fb.group({
      //hidden fields
      phone: [this.currentuser?.phone, []],


      //visible fields
      arabic_first_name: ['', [Validators.required]],
      arabic_last_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  collectModel(): void {
    this.model = new CustomerViewModel();
    this.model.arabic_first_name = this.signupForm.controls['arabic_first_name']?.value;
    this.model.arabic_last_name = this.signupForm.controls['arabic_last_name']?.value;
    this.model.first_name = this.signupForm.controls['first_name']?.value;
    this.model.last_name = this.signupForm.controls['last_name']?.value;
    this.model.email = this.signupForm.controls['email']?.value;
    this.model.phone = this.signupForm.controls['phone']?.value;

  }
  submit(): void {
    this.collectModel();
    this.authService.signup(this.model).subscribe(
      (res:CustomerViewModel) => {
        if(res != null){

          this.currentuserService.updateCurrentUser(res);
          this.notificationService.showSuccessTranslated('success.shared.operationSucceeded','');
          this.router.navigate(['/home']);
        }
      else
      this.notificationService.showErrorTranslated('error.shared.operationFailed','');
      },
      (      error: any) => {
        console.log(error);
      }
    );
  }

  setLanguageSubscriber(): void {
    this.language = this.translateService.currentLang;
    this.subscriptionTranslate = this.translateService.onLangChange.subscribe(
      val => {
        this.language = val.lang;
      },
      error => {},
      () => {}
    );
    this.subscriptions.push(this.subscriptionTranslate);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  setPageTitle(): void {
    this.pageTitle.setTitleTranslated(`shared.signUp`);
  }
}