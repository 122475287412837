import { Injectable } from '@angular/core';
import { Config, XhrService } from '../xhr/xhr.service';
import { LogINInfo } from '../../models/login-info';
import { Observable } from 'rxjs/internal/Observable';
import { CutomerCodeViewModel } from '../../models/customer-code-view-model';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';
import { CustomerViewModel } from '../../models/customer-view-model';
@Injectable({
    providedIn: 'root'
  })
  export class NotificationService extends XhrService {
  
    constructor() {
      super();
    }



    search(model: any): Observable<any> {
        const url = `/Notifications/search`;
        const API_Config: Config ={
            url: url,
            body: model,
        }
        return this.post<any>(API_Config);
    }






   
    GetAllanyAsync(pageIndex:number,pageSize :number,userId :number,sortingType :string): Observable<any> {
        const url = `/NotificationDetails/GetAllanyAsync/${pageIndex}/${pageSize}/${userId}/${sortingType}`;
        const API_Config: Config ={
            url: url,
        }
        return this.get<any>(API_Config);
    }
    



    getAllanyAsync(model: any): Observable<any> {
        const url = `/NotificationDetails/GetAllanyAsync/${model.pagination.pageIndex}/${model.pagination.pageSize}/${model.userId}/${model.sorting}`;
        const API_Config: Config ={
            url: url,
        }
        return this.get<any>(API_Config);
    }

    getCountUnSeenNotificationsByUser(userId: any): Observable<any> {
        const url = `/NotificationDetails/GetCountUnSeenNotificationsByUser/${userId}`;
        const API_Config: Config ={
            url: url,
        }
        return this.get<any>(API_Config);
    }

    seenNotificationByUserAsync(userId: any, notificationId: number): Observable<any> {
        const url = `/NotificationDetails/SeenNotificationByUserAsync/${userId}/${notificationId}`;
        const API_Config: Config ={
            url: url,
        }
        return this.post<any>(API_Config);
    }

    seenNotificationListByUserAsync(userId: any, notificationIds: number[]): Observable<any> {
        const url = `/NotificationDetails/SeenNotificationListByUserAsync/${userId}}`;
        const API_Config: Config ={
            url: url,
            body: notificationIds,
        }
        return this.post<any>(API_Config);
    }

    readNotificationByUser(userId: any, notificationId: number): Observable<any> {
        const url = `/NotificationDetails/ReadNotificationByUser/${userId}/${notificationId}`;
        const API_Config: Config ={
            url: url,
        }
        return this.post<any>(API_Config);
    }

    seenAllNotificationCurrentUserId(): Observable<any> {
        const url = `/NotificationDetails/SeenAllNotificationCurrentUserId`;
        const API_Config: Config ={
            url: url,
        }
        return this.post<any>(API_Config);
    }

    clearPopupNotificationListByUserAsync(userId: any): Observable<any> {
        const url = `/NotificationDetails/ClearPopupNotificationListByUserAsync/${userId}`;
        const API_Config: Config ={
            url: url,
        }
        return this.post<any>(API_Config);
    }
    GetAllNotificationsByUserIdAsync(pageIndex:number,pageSize :number,userId :number,sortingType :string): Observable<any> {
        const url = `/NotificationDetails/GetAllNotificationsByUserIdAsync/${pageIndex}/${pageSize}/${userId}/${sortingType}`;
        const API_Config: Config ={
            url: url,
        }
        return this.get<any>(API_Config);
    }
}
  