import { Component, OnDestroy, OnInit } from '@angular/core';
import { FooterConfig } from '../../configurations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'msn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  config = FooterConfig;
  currentYear = new Date().getFullYear();
  lang: string = 'en';

  constructor(
    private translationService: TranslateService,
  ) {}
  ngOnInit(): void {
    this.lang = this.translationService.currentLang;
    this.translationService.onLangChange.subscribe((lang) => {
      this.lang = lang.lang;
    });
    //this.localizationService.isRtl();
  }
  ngOnDestroy(): void {
    return;
  }

  onChangeLanguage(langCode: string): void {
   // this.localizationService.setLang(langCode);
  }
}
