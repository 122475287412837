export class CustomerViewModel {
    id!: number;
    phone!: string;
    first_name!: string;
    last_name!: string;
    arabic_first_name!: string;
    arabic_last_name!: string;
    email!: string;
    profileImageUrl!: string;
    mobileSerialNumber!: string;
    isActive!: boolean;
}