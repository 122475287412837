<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="pt-5 pb-5">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <!-- <div class="col-md-4 mb-4">
          <div class="profile-pic">
              <label class="-label" for="file">
                  <span class="glyphicon glyphicon-camera"></span>
                  <span class="change-image">Change Image</span>
              </label>
              <input id="file" type="file" onchange="loadFile(event)" />
              <img src="../../../../assets/images/profileman.jpg" id="output" width="200" />
          </div>
          <h4 class="text-center">{{currentuser?.first_name}}</h4>
          <span class="d-flex align-items-center justify-content-center gap-2 "><img src="../../../../assets/images/icons/saudi.svg" class="flag-icon"> {{currentuser?.phone}}</span>
      </div> -->
      <div class="col-md-8">
        <div class="profile-info">
          <!-- <div class="d-flex gap-3 edit-label align-items-center">
            <img src="../../../../assets/images/icons/exclamation.svg">
            <p class="text-white mb-0">{{"shared.calltoEdit" | translate}}</p> 
            <span class="call-number">19019</span>
           </div> -->
            <form class=" mt-5" [formGroup]="editForm">
              <div class="row">
                <div class="col-md-12  mb-4">
                  <label class="text-primary mb-2">{{"shared.username" | translate}}</label>
                  <div class="form-input">
                      <!-- <span class="text-center">{{currentuser?.first_name}} {{currentuser?.last_name}}</span> -->
                      <input class="form-control" formControlName="name" type="text" placeholder="{{'shared.username' | translate}}" readonly>
                      <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                      <!-- <input class="form-control" formControlName="arabic_first_name" type="text" placeholder="Arabic First Name"> -->
                  </div>                                
              </div>
              <!-- <div class="col-md-12  mb-4">
                <label class="text-primary mb-2">{{"shared.usernameArabic" | translate}}</label>
                  <div class="form-input form-control">
                    <span class="text-center">{{currentuser?.arabic_first_name}} {{currentuser?.arabic_last_name}}</span>  
                      <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                  </div>   
              </div> -->
              <div class="col-md-12  mb-4">
                <label class="text-primary mb-2">{{"shared.email" | translate}}</label>
                  <div class="form-input">
                      <input class="form-control" formControlName="email" type="text" placeholder="{{'shared.email' | translate}}" readonly>
                      <img src="../../../../assets/images/icons/sms.svg" class="input-icon" alt="">
                  </div>                        
              </div>
              <div class="col-md-12  mb-4">
                <label class="text-primary mb-2">{{"shared.mobileNumber" | translate}}</label>
                <div class="form-input">
                    <input type="tel" class="form-control" formControlName="phone" placeholder="{{'shared.mobileNumber' | translate}}" readonly>
                    <img src="../../../../assets/images/icons/phone-dark.svg" class="input-icon" alt="">
                </div>
              </div>
              </div>
              <!-- <div class="">
                <button type="submit" (click)="submit()" class="btn btn-primary">Save <span><img src="../../../../../assets/images/icons/arrow.png" alt=""></span></button>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
          

          