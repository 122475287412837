import { Component, Input } from '@angular/core';
import { TitleTypes } from '../../enums';

@Component({
  selector: 'msn-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
  constructor() {}

  Title_TYPES = TitleTypes;

  @Input() title: string = '';

  @Input() type: string = '';

  @Input() addSeparator: boolean = true;
}
