export const FooterConfig = {
  siteMap: {
    features: true,
    about: true,
    blog: true,
    support: true,
    contact: true,
    privacyPolicy: true,
  },
  copyright: {
    show: true,
    owner: 'SmartTech',
  },
  facebook: 'https://www.facebook.com/',
  twitter: 'https://twitter.com/home',
  instagram: 'https://www.instagram.com/',
};
