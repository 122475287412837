<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- Navbar -->
<div class="bg-very-blue-light">
  <div class="container">
    <div class="sub-navbar pt-1 pb-1">
      <div class="topBar">
        <div class="">
          <div class="change-lang">
              <a class="lang" *ngIf="languageService.getLanguage==='ar'" (click)="changeLang('en')">
                <!-- <img src="../../../../assets/images/icons/english-british.svg" class="lang-flag"> -->
                <img src="../../../../assets/images/icons/saudi.svg" class="lang-flag">
                <span>English</span>
              </a>
              <a class="lang" *ngIf="languageService.getLanguage==='en'" (click)="changeLang('ar')">
                <img src="../../../../assets/images/icons/saudi.svg" class="lang-flag">
                <span>العربية</span>
              </a>

          </div>
          <!-- <ul class="d-flex gap-3 call-us mb-0 align-items-center">
                <li><img src="../../../../assets/images/icons/call.svg" alt=""></li>
                <li>  <div class="lang-box p-0">
                  <a class="lang" *ngIf="languageService.getLanguage==='en'" (click)="changeLang('ar')">
                    <span>العربية</span>
                  </a>
                  <a class="lang" *ngIf="languageService.getLanguage==='ar'" (click)="changeLang('en')">
                    <span>English</span>
                  </a>
                </div></li>
              </ul> -->
        </div>
        <div class="d-flex gap-2">
        <!--   <div>
            <img src="../../../../assets/images/icons/help.svg" width="10" class="mx-2">
            <span>{{"menu.help" | translate}}</span>
          </div> -->
          <div *ngIf="!isLoggedIn">
            <img src="../../../../assets/images/icons/loginProfile.svg" width="25" class="mx-2">
            <a routerLink="/login" class="text-black cursor-pointer">{{"menu.login" | translate}}</a>
          </div>
          <div *ngIf="isLoggedIn">
            <img src="../../../../assets/images/icons/logoutProfile.svg" width="25" class="mx-2 flip-horizontally">
            <a routerLink="/home" class="text-black cursor-pointer "  data-bs-toggle="modal" data-bs-target="#logOutModel" >{{"menu.logout" | translate}}</a>
          </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="social-links">
              <ul class="d-flex gap-2 justify-content-end mb-0">
                <li><a href="#" class="social-link-icon"><img src="../../../../assets/images/icons/snap.svg" alt=""></a></li>
                <li><a href="#" class="social-link-icon"><img src="../../../../assets/images/icons/facebook.svg" alt=""></a></li>
                <li><a href="#" class="social-link-icon"><img src="../../../../assets/images/icons/instagram.svg" alt=""></a></li>
                <li><a href="#" class="social-link-icon"><img src="../../../../assets/images/icons/youtube.svg" alt=""></a></li>
                <li><a href="#" class="social-link-icon"><img src="../../../../assets/images/icons/twitter.svg" alt=""></a></li>
              </ul>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</div>
<div class="shadow-bottom-light">
  <div class="container">
    <nav class="pt-2 pb-2">
      <div class="navbar-container">
  <div class="brandMenu">
    <a href="https://www.extra.com/{{lang}}-sa/" class="navbar-brand">
      <img src="../../../../assets/images/eXtra-logo.svg" width="100" alt="logo">
    </a>
    <div>
      <div class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{ 'collapseMenu': menuOpen }">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
      <div class="navbar-links" [ngClass]="{'active': menuOpen}">
        <ng-container *ngFor="let item of navItems">
          <span *ngIf="!item.hide">
            <a [routerLink]="[item.route]" routerLinkActive="active" class="nav-link" aria-current="page">
              {{ item.name | translate }}
            </a>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

<!--         <img src="../../../../assets/images/maskX.png" class="maskX" alt="">
 -->        <div class="d-flex align-items-baseline btns-header">
          <!-- <a class="call-to-action btn-primary underline-none" style="z-index: 10;"
           [routerLink]="isLoggedIn?['/warranty/add']:['/login']"> {{"global.RequestWarranty" | translate}}</a> -->
          <a class="call-to-action btn-primary underline-none" style="z-index: 10;"
           [routerLink]="['/warranty/add']"> {{"global.RequestWarranty" | translate}}</a>

          <!-- <a class="call-to-action btn-secondary underline-none" [routerLink]="['/file-a-claim']"> {{"global.fillClaim"
            | translate}}</a> -->
          <div class="nav-item dropdown notification-menu" #NotificationPill (click)="addActiveNotification(NotificationPill)">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" >
              <div class="notification-bell mx-3">
                <img  src="../../../../assets/images/icons/bell.svg" alt="">
                <span *ngIf="UnSeenNotifications" class="notifications-badge">{{UnSeenNotifications}}</span>
              </div>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <h5 class="fw-600">{{"shared.notifications" | translate}}</h5>
              <li  *ngFor="let item of notificationMenus?.collection">
                <a class="dropdown-item po-cursor"  routerLink="{{item.url}}" >
                  <div class="notification-item">
                    <span class="noti-icon"><img src="../../../../assets/images/icons/bell-n.svg"></span>
                    <div>
                      <h6  *ngIf="languageService.getLanguage==='en'" >{{item.notBodyEn}}</h6>
                      <h6  *ngIf="languageService.getLanguage==='ar'">{{item.notBodyAr}}</h6>

                      <span class="text-primary date-notification">{{item.creationDate|date}}</span>
                    </div>
                  </div>
                </a>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
    </nav>


  </div>
</div>
<!--Logout Modal-->
<!-- Modal -->
<div class="modal fade" data-bs-backdrop="static" id="logOutModel" tabindex="-1" aria-labelledby="deleteModelLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-13 text-center">
          <h5 class="text-black">{{ 'shared.logOutMessage' | translate }}</h5>
        </div>
      </div>
      <div class="modal-footer border-0 mx-auto">
        <button type="button" class="btn btn-primary gray-btn mx-1 min-120" data-bs-dismiss="modal" (click)="logout()">
          {{"menu.logout" | translate}}
        </button>

        <button type="button" class="btn btn-cancel mx-1 min-120" data-bs-dismiss="modal">
          {{ 'shared.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>