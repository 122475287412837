import { Injectable } from '@angular/core';
import { Config, XhrService } from '../xhr/xhr.service';
import { LogINInfo } from '../../models/login-info';
import { Observable } from 'rxjs/internal/Observable';
import { CutomerCodeViewModel } from '../../models/customer-code-view-model';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';
import { CustomerViewModel } from '../../models/customer-view-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends XhrService {

  constructor() {
    super();
  }

  login(item: LogINInfo): Observable<LoginResponse> {
    const url = `/Account/GetToken`;
    const API_Config: Config ={
      url: url,
      body: item,
     
    }
    return this.post<LoginResponse>(API_Config );
  }
  SendCustomerCode(mobileNo: string): Observable<CutomerCodeViewModel > {
    const url = `/Account/SendCustomerCode`;
    const API_Config: Config ={
      url: url,
      params: {
        mobileNo: mobileNo,
      }
     
    }
    return this.get<CutomerCodeViewModel>(API_Config );
  }
  signup(model:CustomerViewModel): Observable<CustomerViewModel> {
    const url = `/Account/signup`;
    const API_Config: Config ={
      url: url,
      body: model,
     
    }
    return this.post<CustomerViewModel>(API_Config );
  }
}
