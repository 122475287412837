<button
  (click)="btnClick($event)"
  type="{{ role }}"
  class="btn unil-btn cursor-pointer"
  [ngClass]="{
    'unil-primary-btn': type === BTN_TYPES.primary,
    'unil-simple-btn': type === BTN_TYPES.simple,

    disabled: disabled,
    'unil-small-btn': size === 'sm'

  }"
  [disabled]="disabled"
  chkRtl
  [title]="title | translate"
>
  <span class="unil-button-title">{{ title | translate }}</span>
</button>
