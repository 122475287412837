 
<div class="login-page container p-0">
    <div class="row align-items-center">
        <div class="col-md-5 full-bg">
            <div class="">
                <img src="../../../../assets/images/signUp.svg" class="w-100" alt="logo">
            </div>
        </div>
        <div class="col-md-7">
            <!-- <div class="lang-box">
                <a class="lang" *ngIf="languageService.getLanguage==='en'" (click)="changeLang('ar')">
                  <span>العربية</span>
                  <img src="../../../../../assets/images/language.svg" alt="img">
                </a>
                <a class="lang" *ngIf="languageService.getLanguage==='ar'" (click)="changeLang('en')">
                  <span>English</span>
                  <img src="../../../../../assets/images/language.svg" alt="img">
                </a>
              </div> -->
            <div class="login-side-frame">
                <div class="login-side">
                    <h6 class="text-primary mb-3 text-start">{{'user.welcome'|translate}}</h6>
                    <p class="fs-16 text-start"> {{'user.signUpDesc'|translate}}</p>
    
                    <form class="login-form mt-5" [formGroup]="signupForm">
                        <div class="row">
                            <!-- <div class="col-md-6  mb-4">
                                <div class="form-input">
                                    <input class="form-control" formControlName="arabic_first_name" type="text" placeholder="Arabic First Name">
                                    <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                                </div>                                
                            </div>
                            <div class="col-md-6  mb-4">
                                <div class="form-input">
                                    <input class="form-control" formControlName="arabic_last_name" type="text" placeholder="Arabic Last Name">
                                    <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                                </div>   
                            </div> -->
                            <div class="col-md-6  mb-4">
                                <div class="form-input">
                                    <input class="form-control" formControlName="first_name" type="text" placeholder="{{'shared.firstName' | translate }}">
                                    <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                                </div>                                
                            </div>
                            <div class="col-md-6  mb-4">
                                <div class="form-input">
                                    <input class="form-control" formControlName="last_name" type="text" placeholder="{{'shared.lastName' | translate }}">
                                    <img src="../../../../assets/images/icons/profile.svg" class="input-icon" alt="">
                                </div>   
                            </div>
                            <div class="col-md-12  mb-4">
                                <div class="form-input">
                                    <input class="form-control" formControlName="email" type="text" placeholder="{{'shared.email' | translate }}">
                                    <img src="../../../../assets/images/icons/sms.svg" class="input-icon" alt="">
                                </div>                        
                            </div>
                            <!-- <div class="col-md-12 mb-4">
                                <div class="form-input">
                                    <form [formGroup]="phoneForm" class="login-form">
                                        <div class="mb-3 phone-input">
                                         <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                [selectFirstCountry]="false" [maxLength]="15" [phoneValidation]="true"
                                                [separateDialCode]="true" name="phone" formControlName="phone">
                                            </ngx-intl-tel-input> 
                                        </div>
                                    </form>
                                    <img src="../../../../assets/images/icons/phone-dark.svg" class="input-icon" alt="">
                                </div>
                            </div> -->
                        </div>
                        <div class="">
                            <button type="submit" (click)="submit()" class="btn btn-primary">{{"shared.signUpNow"|translate}}</button>
                        </div>
                    </form>
                    <!-- <p class="mt-3">{{'user.donHaveAccount'|translate}} <a [routerLink]="['/login']" routerLinkActive="router-link-active"  class="text-primary underline-none">{{'shared.login'|translate}}</a>
                    </p> -->
                </div>
            </div>
            <!-- <p class="text-center">{{'shared.copyRight'|translate}}, <a href="#" class="text-primary underline-none">{{'global.appName'|translate}}</a>© {{ currentYear }}</p> -->
        </div>
     </div>
</div>