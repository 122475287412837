<div class="preloader">
  <!--   <svg width="261" height="192" viewBox="0 0 261 192" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path" d="M236.747 114.808H219.556L219.826 126.857L233.867 126.661C239.447 126.563 242.958 126.27 242.958 131.559V132.441C226.036 132.931 213.525 137.829 213.525 150.955C213.525 161.731 221.626 166.825 236.657 166.825C245.568 166.825 253.039 165.355 257.269 163.004V132.735C257.449 118.825 248.448 114.808 236.747 114.808ZM243.048 154.58C241.698 155.265 239.718 155.755 237.557 155.755C231.617 155.755 228.197 154.482 228.197 150.661C228.197 142.335 233.957 143.412 243.138 142.629V154.58H243.048Z" fill="#0065A4"/>
      <path class="path" d="M183.823 117.747V166.335H199.574V127.543C201.734 126.563 208.305 126.465 212.625 126.955V114.808C199.394 114.318 189.223 115.102 183.823 117.747Z" fill="#0065A4"/>
      <path class="path" d="M92.1052 145.959C92.1052 125.486 88.1448 113.927 68.7032 113.927C53.852 113.927 44.2212 124.408 44.2212 140.473C44.2212 160.065 54.6621 166.237 71.2235 166.335H87.4248L87.1548 154.482H72.1236C66.4531 154.482 60.4226 153.894 60.9626 145.959H92.1052ZM60.6025 133.714C60.6025 129.11 62.4027 124.408 69.2433 124.408C75.7238 124.408 77.614 126.955 77.614 133.714H60.6025Z" fill="#0065A4"/>
      <path class="path" d="M167.802 103.837H152.05V152.229C152.05 160.065 157.541 167.118 168.342 167.118C171.852 167.118 174.552 166.237 175.812 165.649V155.167C174.552 155.559 173.832 155.657 172.572 155.657C169.152 155.657 167.712 153.6 167.712 149.682V126.857H178.422V114.906H167.802V103.837Z" fill="#0065A4"/>
      <path class="path" d="M214.335 26.7429H229.726V95.2164H214.335V26.7429Z" fill="#0065A4"/>
      <path class="path" d="M1.2876 26.7429H16.4088V95.2164H1.2876V26.7429Z" fill="#0065A4"/>
      <path class="path" d="M53.6718 43.7879C57.6322 43.7879 60.9625 39.7715 60.9625 36.0491C60.9625 32.1307 57.6322 28.1144 53.6718 28.1144C49.5315 28.1144 46.3812 32.1307 46.3812 35.9511C46.2912 39.7715 49.5315 43.7879 53.6718 43.7879Z" fill="#0065A4"/>
      <path class="path" d="M69.6034 43.7879C73.6537 43.7879 76.894 39.7715 76.894 36.0491C76.894 32.1307 73.6537 28.1144 69.6034 28.1144C65.553 28.1144 62.4028 32.1307 62.4028 35.9511C62.4028 39.7715 65.553 43.7879 69.6034 43.7879Z" fill="#0065A4"/>
      <path class="path" d="M206.955 74.7429V70.3347C206.955 57.8939 199.664 50.3511 186.433 50.3511C186.253 50.3511 185.623 50.449 185.353 50.449L177.792 62.498H184.993C189.673 62.498 191.563 65.5348 191.563 69.2572V74.645C191.563 78.4654 189.763 81.4042 184.993 81.4042H152.68V52.4082H137.019V81.4042H127.298V52.4082H111.817V81.4042H101.916V52.4082H86.5246V81.4042H69.9632V52.4082H54.3919V81.4042H43.051V52.4082H27.9297V90.5143C27.9297 100.8 25.5895 104.327 20.4591 106.09V115.984C31.17 115.494 40.3508 108.049 42.5109 95.3143H186.433C199.664 95.2164 206.955 87.1837 206.955 74.7429Z" fill="#0065A4"/>
      <path class="path" d="M74.4637 192H92.8253L117.667 153.404L126.668 166.335H146.11L127.208 138.906L150.7 103.543H132.699L118.477 124.898L111.907 114.808H93.0953L108.847 139.396L74.4637 192Z" fill="#FFF200"/>
      <path class="path" d="M199.754 0L159.341 62.498H177.702L217.756 0H199.754Z" fill="#FFF200"/>
    </svg> -->
    <img src="../../../../../assets/images/extra-loader.gif" alt="eXtra Loader" width="80">
  </div>
