import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ConnectionService } from '../connection/connection.service';
import { environment } from 'src/environments/environment';
import { LoginResponse } from 'src/app/modules/shared/models/login-response';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '../auth/currentuser.service';

export interface Config {
  url?: string;
  body?: any;
  params?: {
    [param: string]: string | number | boolean | (string | number | boolean)[];
  };
}

interface API_Response<T> {
  Data: T;
  Message: string;
  Status: number;
}

@Injectable({
  providedIn: 'root',
})
export class XhrService {
  private domainURL = environment.backendURL;
  private http = inject(HttpClient);
  private isConnected = inject(ConnectionService).isConnected;
  private _translateService = inject(TranslateService);
  private _currentUserService = inject(CurrentUserService);


  private currentUser = 'currentUser';
  User: LoginResponse | undefined;

  
  protected httpOptions = {
    headers: new HttpHeaders({
      'content-type': 'application/json',
      language: 'en',
    }),
  };
  private setHeaders(): void {
    ;
    let currentLang = this._translateService.currentLang;
    if (currentLang == null) {
      currentLang = 'en';
    }
    const currentUser = localStorage.getItem(this.currentUser);
    this.User = currentUser != null ? JSON.parse(currentUser) : '';
    if (this.User) {
      ;

      this.httpOptions = {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          language: currentLang,
          'Access-Control-Allow-Origin': '*',
           Authorization: "Bearer" + ' ' + this.User.token,
          //'Tenant-ID': tenantId!,
        }),
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'content-type': 'application/json',
          language: currentLang,
        }),
      };
    }
  }

  get<T>(API_Config: Config) {
    if (!this.isConnected) return throwError(() => new Error('DISCONNECTED'));
this.setHeaders();
    return this.http
      .get<API_Response<T>>(`${this.domainURL}${API_Config.url}`, {
        params: API_Config.params,
        headers: this.httpOptions.headers,

      })
      .pipe(
        map((event:any) => {
          return event;
        }),
        catchError(this.handleError<T>(`getData`))
      );
  }

  post<T>(API_Config: Config) {
    if (!this.isConnected) return throwError(() => new Error('DISCONNECTED'));
    this.setHeaders();

    return this.http
      .post<API_Response<T>>(
        `${this.domainURL}${API_Config.url}`,
        API_Config.body,
        {
          headers: this.httpOptions.headers,
          params: API_Config.params,
        }
      )
      .pipe(
        map((event:any) => {
          return event;
        }),
        catchError(this.handleError<T>(`postData`))
      );
  }

  // postData<T>(API_Config: Config) {
  //   if (!this.isConnected) return throwError(() => new Error('DISCONNECTED'));

  //   return this.http
  //     .post<T>(
  //       `${this.domainURL}${API_Config.url}`,
  //       API_Config.body,
  //       {
  //         params: API_Config.params,
  //       }
  //     )
  //     .pipe(
  //       map((event) => {
  //         return event;
  //       })
  //     );
  // }
  put<T>(API_Config: Config) {
    if (!this.isConnected) return throwError(() => new Error('DISCONNECTED'));

    return this.http
      .put<API_Response<T>>(
        `${this.domainURL}${API_Config.url}`,
        API_Config.body,
        {
          headers: this.httpOptions.headers,

          params: API_Config.params,
        }
      )
      .pipe(
        map((event) => {
          return event;
        }),
        catchError(this.handleError<T>(`putData`))
      );
  }

  delete<T>(API_Config: Config) {
    if (!this.isConnected) return throwError(() => new Error('DISCONNECTED'));

    return this.http
      .delete<API_Response<T>>(`${this.domainURL}${API_Config.url}`, {
        body: API_Config.body,
        headers: this.httpOptions.headers,

        params: API_Config.params,
      })
      .pipe(
        map((event) => {
          return event;
        }),
        catchError(this.handleError<T>(`deleteData`))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status == 401) {
        this._currentUserService.logOut();
        return new Observable();
      } else {
        // TODO: send the error to remote logging infrastructure
        //console.error(JSON.stringify(error)); // log to console instead

        // TODO: better job of transforming error for user consumption
        //this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        //return of(result as T);
        throw error;
      }
    };
  }
}
