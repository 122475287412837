<footer class="pt-5 pb-3">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-5">
        <div class="d-md-flex align-items-center gap-5">
          <img src="../../../../assets/images/eXtra-logo-white.svg" class="footer-logo" alt="logo">
          <div class="desc text-white">
            <h5 class="">{{ "footer.subDesc" | translate }}</h5>
            <span class="mb-4 small-desc">{{ "footer.desc" | translate }}</span>
          </div>
        </div>
        <Div class="d-flex mt-5 gap-3 align-items-center">
          <Div class="qr-code">
            <img src="../../../../assets/images/qr-code.png" class="w-100" alt="">
            <span>{{'footer.scanToDownload'|translate}}</span>
          </Div>
          <div class="btns-app-download">
            <div>
              <a href="https://apps.apple.com/sa/app/extra-services/id1459789218" target="_blank" class="underline-none mb-2"><img src="../../../../assets/images/Download_on_the.png" alt=""> </a>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.smarttechsys.extra&pli=1" target="_blank" class="underline-none mb-2"><img src="../../../../assets/images/google-play-bad.png" alt=""></a>
            </div>
            <div>
              <a href="https://appgallery.huawei.com/app/C100938241" target="_blank" class="underline-none mb-2"><img src="../../../../assets/images/appGallery.png" alt=""></a>
            </div>
          </div>
        </Div>
        <div class="supported-icons mt-4">
          <img src="../../../../assets/images/supported/AXP_footerlogo.png" alt="">
          <img src="../../../../assets/images/supported/mc_footerlogo.png" alt="">
          <img src="../../../../assets/images/supported/visa_footerlogo.png" alt="">
          <img src="../../../../assets/images/supported/Mada_footerlogo.png" alt="">
          <img src="../../../../assets/images/supported/thawte_footerlogo.png" alt="">
        </div>
      </div>
      <div class="col-md-3 text-white">
        <div class="row">
          <div class="col-lg-12">
            <span class="footer-nav-title">{{'footer.aboutUs'|translate}}</span>
            <ul class="footer-nav">
              <li>
                <a href="https://www.extra.com/{{lang}}-sa/aboutextra" target="_blank">{{ "menu.companyInformation" | translate }}</a>
              </li>
              <li>
                <a href="http://investors.extrastores.com/index{{lang=='en'?'':'_ar'}}.html" target="_blank">{{ "menu.investorRelations" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/{{lang}}-sa/corporateSales" target="_blank">{{ "menu.corporateSales" | translate }}</a>
              </li>
              <li>
                <a href="https://www.bayt.com/{{lang}}/company/united-electronics-company-extra-2058853/" target="_blank">{{ "menu.careers" | translate }}</a>
              </li>
            </ul>
          </div>
          <!-- <div class="col-lg-4">
            <span class="footer-nav-title">{{'footer.ourServices'|translate}}</span>
            <ul class="footer-nav">
              <li>
                <a href="https://www.extra.com/ar-sa/extrasetup" target="_blank">{{ "menu.extraSet-Up" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/extrasafeguard2" target="_blank">{{ "menu.extraSafeguard" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/extracare" target="_blank">{{ "menu.extraCare" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/extrarepair" target="_blank">{{ "menu.extraRepair" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/maintenance" target="_blank">{{ "menu.maintenance&Repair" | translate }}</a>
              </li>
            </ul>
          </div> -->
          <!-- <div class="col-lg-4">
            <span class="footer-nav-title">{{'footer.needHelp'|translate}}</span>
            <ul class="footer-nav">
              <li>
                <a href="https://www.extra.com/ar-sa/faqs" target="_blank">{{ "menu.FAQs" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/baseeta" target="_blank">{{ "menu.tasheel" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/tamaraterms" target="_blank">{{ "menu.tamara" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/returnexchange" target="_blank">{{ "menu.return&Exchange" | translate }}</a>
              </li>
              <li>
                <a href="https://www.extra.com/ar-sa/yourrights" target="_blank">{{ "menu.knowYourRights" | translate }}</a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="col-md-2 text-white">
        <span class="footer-nav-title">{{'footer.contactUs'|translate}}</span>
        <span class="small-desc mb-3">{{'footer.findUs'|translate}}</span>
        <ul class="social-icons mb-3">
          <li><a href="https://www.facebook.com/extrastores" target="_blank"><img src="../../../../assets/images/icons/facebook-solid.svg" alt=""></a></li>
          <li><a href="https://x.com/eXtraStores" target="_blank"><img src="../../../../assets/images/icons/twitter.svg" alt=""></a></li>
          <li><a href="https://www.instagram.com/extrastores/" target="_blank"><img src="../../../../assets/images/icons/instagram-solid.svg" alt=""></a></li>
          <li><a href="https://www.youtube.com/extrastores" target="_blank"><img src="../../../../assets/images/icons/youtube-solid.svg" class="mt-2" alt=""></a></li>
        </ul>
        <span class="small-desc mb-3">{{'footer.callUs'|translate}}</span>
        <div class="d-flex gap-3 align-items-start">
          <img src="../../../../assets/images/icons/call.svg" alt="">
          <div>
            <span class="small-desc ltr text-start"><a href="tel:920004123" class="text-white"> 92 000 41 23</a></span>
            <span class="small-desc ltr"><a href="tel:8001240900" class="text-white"> 800 12 40 900</a></span>
          </div>
        </div>

        <div class="mt-4">
          <span class="extra-small-desc">
            {{"shared.SaturdayThursday"|translate}}
            : 9:00 {{"shared.AM"|translate}} - 10:00 {{"shared.PM"|translate}}
          </span>
          <span class="extra-small-desc">
            {{"shared.Friday"|translate}}
            : 4:00 {{"shared.PM"|translate}} - 10:00 {{"shared.PM"|translate}}
          </span>
        </div>
        <div class="mt-4">
          <span class="small-desc mb-3">{{'footer.extraStores'|translate}}</span>
          <a href="https://www.extra.com/ar-sa/store-finder" target="_blank">
            <div class="d-flex align-items-center gap-4">
              <img src="../../../../assets/images/icons/shop.svg" class="shop-icon" alt="">
              <span class="text-white">{{'footer.seeOurStores'|translate}}
                <img src="../../../../assets/images/icons/angle-next.svg" width="20" alt="" class="flip-horizontally"></span>
            </div>
          </a>
          <span class="extra-small-desc mt-3 ">{{'footer.workingHourStores'|translate}}</span>
        </div>
      </div>
    </div>


    <div class="footer-bottom mt-5 align-items-center">
      <div class="copyright-text-2 small-desc">
        {{'shared.copyRight'|translate}} {{ currentYear }} <a href="#" class="text-white underline-none">Extra.com</a>. {{"shared.rightsReserved" | translate}}
      </div>
      <div>
        <img src="../../../../assets/images/supported/ExtraBPTW_Logo.png" width="200" alt="">
      </div>
      <div class="copyright-text-2">
        <div class="d-flex gap-3 small-desc">
          <a target="_blank" href="https://www.extra.com/ar-sa/privacypolicy" class="underline-none text-white ">{{ "menu.privacyPolicy" | translate }}</a>
          <a href="../../../../../assets/docs/eXtraDevices_merged.pdf" target="_blank" class="underline-none text-white">{{ "menu.terms" | translate }}</a>
        </div>
      </div>
    </div>
    <div class="text-center">
      <span class="small-desc mb-4">
        {{'footer.copyDesc'|translate}}
      </span>
      <!-- <span class="mci-logo">
        <img src="../../../../assets/images/supported/MCI_disLogo.jpg" alt="">
      </span> -->
    </div>
  </div>
</footer>
