import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/core/components/login/login.component';
import { SignupComponent } from './modules/core/components/signup/signup.component';
import { EditProfileComponent } from './modules/components/edit-profile/edit-profile.component';
import { ContactComponent } from './modules/components/contact/contact.component';
import { TermsComponent } from './modules/components/terms/terms.component';
import { PrivacyPolicyComponent } from './modules/components/privacy-policy/privacy-policy.component';
import { FileAClaimComponent } from './modules/file-a-claim/file-a-claim.component';
import { DownloadAppComponent } from './modules/core/components/download-app/download-app.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'warranty',
    loadChildren: () =>
      import('./modules/warranty/warranty.module').then((m) => m.WarrantyModule),
  },
  { path: 'login', component: LoginComponent},
  { path: 'editProfile', component: EditProfileComponent},

  { path: 'signup', component: SignupComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'privacyPolicy', component: PrivacyPolicyComponent},
  { path: 'file-a-claim', component: FileAClaimComponent},
  {path: 'download-app', component:DownloadAppComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
