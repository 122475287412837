import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PageTitleService } from '../../core/services/page-title.service';
import { LoginResponse } from '../../shared/models/login-response';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services';
import { CurrentUserService } from '../../core/services/auth/currentuser.service';
import { NotificationService } from '../../core/services/notification.service';
import { CustomerViewModel } from '../../core/models/customer-view-model';

@Component({
  selector: 'msn-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  editForm!: FormGroup;
  currentuser!:LoginResponse|null;
  model!:CustomerViewModel;
  constructor(
    private fb: FormBuilder,
    private pageTitle: PageTitleService,
    private currentuserService: CurrentUserService,
    private router :Router,
    private authService: AuthService,
    private notificationService: NotificationService

  
  ) {}
  ngOnInit(): void {
    this.currentuser=this.currentuserService.getCurrentUser();
    this.buildForm();
    this.setPageTitle();
    if(!this.currentuser){
       this.router.navigate(['/login']);
    }
    this.setValues();
  }
  setValues(): void {
    // this.editForm.controls['arabic_first_name'].setValue(this.currentuser?.arabic_first_name);
    // this.editForm.controls['arabic_last_name'].setValue(this.currentuser?.arabic_last_name);
    // this.editForm.controls['first_name'].setValue(this.currentuser?.first_name);
    this.editForm.controls['name'].setValue(this.currentuser?.first_name+" "+this.currentuser?.last_name);
    this.editForm.controls['email'].setValue(this.currentuser?.email);
    this.editForm.controls['phone'].setValue(this.currentuser?.phone);
  }
  setPageTitle(): void {
    this.pageTitle.setTitleTranslated(`menu.profile`);
  }
  collectModel(): void {
    this.model = new CustomerViewModel();
    this.model.arabic_first_name = this.editForm.controls['arabic_first_name']?.value;
    this.model.arabic_last_name = this.editForm.controls['arabic_last_name']?.value;
    this.model.first_name = this.editForm.controls['first_name']?.value;
    this.model.last_name = this.editForm.controls['last_name']?.value;
    this.model.email = this.editForm.controls['email']?.value;
    this.model.phone = this.editForm.controls['phone']?.value;

  }
  submit(): void {
    this.collectModel();
    this.authService.signup(this.model).subscribe(
      (res:CustomerViewModel) => {
        if(res != null){

          this.currentuserService.updateCurrentUser(res);
          this.notificationService.showSuccessTranslated('success.shared.operationSucceeded','');
          this.router.navigate(['/home']);
        }
      else
      this.notificationService.showErrorTranslated('error.shared.operationFailed','');
      },
      (      error: any) => {
        console.log(error);
      }
    );
  }
  buildForm(): void {
    this.editForm = this.fb.group({
      phone: [this.currentuser?.phone, []],


      //visible fields
      arabic_first_name: ['', [Validators.required]],
      arabic_last_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    
    });
  }
}
