import { environment } from 'src/environments/environment';
import { LanguageService } from './language';
// import { NotificationReturn } from './../models/Notifiction.model';
import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { EventEmitterService } from './EventEmitterService';
import { NotifyEntityTypeEnum } from '../enums';
@Injectable({
  providedIn: 'root',
})
export class HubNotifictionService {
  public data!: any;
  private hubConnection!: signalR.HubConnection;
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.baseBackendURL+'/notifyExtraOutServiceHub', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();
    this.hubConnection
      .start()
      .then()
      .catch((err: string) => {});
  };
  constructor(public Notification: NotificationService,    private _eventEmitterService: EventEmitterService,

    private _languageService:LanguageService) {}
  public addNotificationListener() {
    this.hubConnection.on('BroadcastMessage', data => {
      this.data = data;
      // if(data.userId==this.userId){
        
      // //  if( this._languageService.getLanguage=='en'){

      // //   this.Notification.showInfo(this.data.dataEn, 'dataset Review');
      // // }
      // //   else
      // //   this.Notification.showInfo(this.data.dataAr, 'dataset Review');

      // }
      switch (data.notifyEntityType) {
        case NotifyEntityTypeEnum.Wrong:
          break;

        case NotifyEntityTypeEnum.Notification:
          this._eventEmitterService.onReloadNotifications.emit();
          break;
      }
    });
  }

 public userId:number|undefined
}
