import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../core/services/page-title.service';

@Component({
  selector: 'msn-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor(private pageTitle: PageTitleService) {}

  ngOnInit(): void {
    this.setPageTitle();
  }
  setPageTitle(): void {
    this.pageTitle.setTitleTranslated(`menu.contactUs`);
  }
}
