<div class="py-5">
    <div class="container">
        <div class="mb-4 text-center">
            <h2 class="termsTitle">
                  {{"menu.terms" | translate }}
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <p>
                    Welcome to www.extra.com (Referred to as “the website/ online service).
    
    This website is provided by eXtra, a subsidiary of United Electronics Company in Saudi Arabia. The website is operated by eXtra (referred to as "we/our/us"). As a user (referred to as "you/your/Customer") of the website, you must read and acknowledge below the following terms and conditions (the “Terms and Conditions”).By purchasing via the website, you agree to be bound by the Terms and Conditions.
    
    Please read carefully before using the online service. We reserve the right to edit these Terms and Conditions at any time. Using the website after editing any of the Terms and Conditions is considered approval to be bound by these modified Terms and Conditions.
                </p>
                <h4>You are prohibited from violating or attempting to violate the security of the website, including and not limited to:</h4>
                <ul>
                    <li>Accessing data not intended for you or logging onto a server or an account that you are not authorized to access.</li>
                    <li>Attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization.</li>
                    <li>Attempting to interfere with service to any other user, host, or network, including, without limitation, via means of submitting a virus to the website, overloading, and “flooding”, “spamming,“ mail bombing “or crashing".</li>
                    <li>Sending unsolicited email, including promotions and/or advertising of products or services.</li>
                    <li>Forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting.</li>
                </ul>
                <p>As you use the website and communicate with us electronically, you also consent to receive any type of our electronic communication including Emails, Newsletters, SMS, and notifications from our website, unless you initiate a request to stop the advertising communication from eXtra by SMS or Email.</p>
            </div>
        </div>
    </div>
</div>