<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="login-page container">
    <div class="row">
        <div class="col-md-5 full-bg">
            <div class="row login-logo-side justify-content-center">
                <div class="">
                    <img src="../../../../assets/images/login.svg" class="w-100" alt="logo">

              </div>
            </div>
        </div>
        <div class="col-md-7">

            <div class="login-side-frame">

                <div class="login-side">
                    <h6 class="text-primary mb-3 ">{{'user.welcome'| translate}}</h6>
                    <p class="fs-16 w-75 mx-auto"> {{'user.loginDesc' | translate}}
                    </p>

                    <form [formGroup]="phoneForm" class="login-form mt-5">

                        <div class="mb-3">
                          <div class="phone-input input-icon">
                            <!-- <p-inputMask 
                            mask="599999999" 
                            slotChar="X"
                            autoClear="false"
                            placeholder="5XXXXXXXX"  formControlName="phone" class="form-control" /> -->
                            <!-- [(ngModel)]="value"  -->
                            
                            <input type="tel" class="form-control" id="exampleInputtel1" maxlength="14" placeholder="5XXXXXXXX "
                            name="phone" formControlName="phone" >
                            <span class="countryCode">+966 </span>
                       <img src="../../../../../assets/images/phone.svg" alt="">
                    </div>
                            <!-- [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" -->
                      <!--    <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"  [onlyCountries]="[CountryISO.SaudiArabia]"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [selectFirstCountry]="true" [maxLength]="9" [phoneValidation]="true"
                                [separateDialCode]="true" name="phone" formControlName="phone"          [selectedCountryISO]="CountryISO.SaudiArabia"
                                >
                            </ngx-intl-tel-input>  -->
                            <msn-control-error-display [control]="getControlphone('phone')" patternMessage="phone">
                            </msn-control-error-display>
                        </div>
                        <div class="mt-5">


                            <button type="submit" class="btn btn-primary" (click)="submit()" id="submitbtn" disabled>{{'shared.sendOtp'|translate}}
                            </button>
                              <button id="openModal" data-bs-toggle="modal" data-bs-target="#otpModal" style="display: none;"></button>
                              <button id="openModalloader" data-bs-toggle="modal" data-bs-target="#loader" style="display: none;"></button>

                        </div>
                    </form>

                    <!-- <p class="mt-5">{{'user.donHaveAccount' |translate}}<a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="text-primary underline-none"> {{'shared.signUp'|translate}}</a>
                    </p> -->
                </div>
            </div>
            <!-- <p class="text-center">{{'shared.copyRight'|translate}}, <a href="#" class="text-primary underline-none">{{'global.appName'|translate}}</a>© {{ currentYear }}
            </p> -->
        </div>
    </div>
</div>
<div #loader class="modal fade" data-bs-backdrop="static" id="loader" tabindex="-1" aria-labelledby="loaderLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
<div class="d-flex justify-content-center align-items-center" style="height: 100%; width: 100%;">
      <msn-app-loader></msn-app-loader>
</div>

    <button id="closeModalloader" data-bs-dismiss="modal" class="btn btn-primary" style="display: none;"></button>
  </div>

</div>
<!-- otp Modal -->
<div #otpModal class="modal fade" data-bs-backdrop="static" id="otpModal" tabindex="-1" aria-labelledby="otpModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title">{{"shared.numberVerification"|translate}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="otpForm.controls['code'].setValue(null);otpForm.markAsUntouched()" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
          <div class="mb-13 px-4 form-login">
            <div class="text-center mb-4 mt-4">
              <img src="./assets/images/OTP.svg" alt="" width="200" />
            </div>

            <!-- <h5 class="text-primary mt-4 text-center"> {{ "OTP" | translate }} </h5> -->
            <div class="row justify-content-center">
              <div class="col-md-9 text-center">
                <p class="otp-desc">{{"shared.numberVerificationDesc"|translate}}</p>
              </div>
            </div>
          <!--   <p class="mb-4 fs-6"> {{ "OTP.Body" | translate }} </p>  533168706 -->
            <form [formGroup]="otpForm" (keyup.enter)="VerifyOTP();">
              <div class="otp-container text-center d-flex flex-column flex-md-row gap-2 mb-4">
                <input type="tel" formControlName="code" class="min-form-control form-control text-center otp-input" id="code" placeholder="- - - - - -" maxlength="6" pattern="\d{6}">
                <button type="button" (click)="VerifyOTP()" id="otbsubmitbtn" disabled class="btn btn-primary otp-btn">{{"shared.confirmOTP"| translate }}</button>


              </div>
              <p class="text-center d-flex flex-column flex-md-row gap-2 mb-4"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &ThickSpace; </span>
                <msn-control-error-display [control]="getControlotp('code')"></msn-control-error-display>
              </p>

              <div class="text-center resend-btn">
                <a type="button" data-bs-dismiss="modal" (click)="submit()" class="">{{"shared.resendOTP"| translate }}</a>
              </div>
              
            </form>
            <button id="closeModal" data-bs-dismiss="modal" class="btn btn-primary" style="display: none;"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
