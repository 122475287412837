<div class="download-app">
  <div class="container">
    <div class="row">
      <div class="col-md-7 text-center mx-auto">
        <span class="home-label">{{"home.label" | translate}}</span>
        <h2 class="feature-title mt-2">{{"home.downloadApp" | translate}}</h2>
        </div>
        </div>
        <div class="row">
          <div class="col-md-7 text-center mx-auto">
            <a href="https://play.google.com/store/apps/details?id=com.smarttechsys.extra&pli=1" target="_blank">
              <img src="../../../../../assets/images/google-play-bad.png" alt="Google Play Badge" width="200">
            </a>
          </div>
          <div class="col-md-7 text-center mx-auto">
          </div>
          <div class="col-md-7 text-center mx-auto">
            <a href="https://apps.apple.com/sa/app/extra-services/id1459789218" target="_blank">
              <img src="../../../../../assets/images/Download_on_the.png" alt="App Store Badge" width="200">
            </a>
            </div>
        </div>
  </div>
</div>
