import { Component, Input } from '@angular/core';

@Component({
  selector: 'msn-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() title: string = '';
  @Input() desc: string = '';
  @Input() icon: string = '';
  @Input() classColor: string = '';
  @Input() image: string = '';
}
