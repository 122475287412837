import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './modules/core/core.module';
// import { SignupComponent } from './modules/components/signup/signup.component';
import { EditProfileComponent } from './modules/components/edit-profile/edit-profile.component';
import { ContactComponent } from './modules/components/contact/contact.component';
import { TermsComponent } from './modules/components/terms/terms.component';
import { PrivacyPolicyComponent } from './modules/components/privacy-policy/privacy-policy.component';
import { ToastrModule } from 'ngx-toastr';
import { FileAClaimComponent } from './modules/file-a-claim/file-a-claim.component';
import { register } from 'swiper/element/bundle';

register();
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [AppComponent, EditProfileComponent, ContactComponent, TermsComponent, PrivacyPolicyComponent, FileAClaimComponent],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    
    ToastrModule.forRoot({
      closeButton: true,
      easeTime: 500,
      enableHtml: true,
      progressBar: true,
      progressAnimation: 'increasing',
    }),
    HttpClientModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
