<div class="info-box my-4" [ngClass]="{}" chkRtl>
  <div class="icon" *ngIf="icon">
    <span [class]="[icon,classColor]"></span>
  </div>
  <div class="" *ngIf="image">
    <img [src]="image">
  </div>
  <div class="content">
    <h4>{{ title | translate }}</h4>
    <p>{{ desc | translate }}</p>
  </div>
  <div class="gradient-shadow-holder">
    <div class="gradient-shadow"></div>
  </div>
</div>
